import useFetch from 'use-http';
export const useContactProfile = () => {
  const { request, get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    { cachePolicy: 'no-cache' }
  );
  async function saveContactProfile(body, onSuccess, onError) {
    await post('/name/add', body);
    if (response.ok) {
      onSuccess && onSuccess();
    }
    if (error) {
      onError && onError();
    }
  }

  async function getContactProfiles(onSuccess, onError) {
    const contacts = await get('/name');
    if (response.ok) {
      onSuccess && onSuccess(contacts);
    }
    if (error) {
      onError && onError();
    }
  }

  return [saveContactProfile, getContactProfiles, loading];
};
