import _ from 'lodash';

export const generateBatchUploadContacts = (selected, people) => {
  const body = [];
  selected.forEach((e) => {
    let name = _.get(people[e], 'NAME', '');
    let firstName = _.get(people[e], 'FIRST NAME', '');
    let lastName = _.get(people[e], 'LAST NAME', '');
    if (name === '' && (firstName === '' && lastName === '')) {
      return body;
    } else {
      if (name === '') {
        name = firstName + ' ' + lastName;
      }
      body.push({
        phoneNumber: e,
        name: name
      });
    }
  });
  return body;
};
