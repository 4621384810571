import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { setManualSender } from '../../../Librarys/Redux/Slices/ManualSenderSlices';
import { editSelected } from '../../../Librarys/Redux/Slices/SenderSlices';
import { useManualSender } from '../ManualSenderContext';

export function ContactTable() {
  const { tableHeaders, people, selected } = useSelector(
    (state) => state.sender
  );
  const usedispatch = useDispatch();
  const { updateManualSender } = useManualSender();
  const selectHandler = (person) => {
    usedispatch(editSelected(person));
  };

  const sendManualBtnHandler = (value) => {
    updateManualSender('set', {
      firstName: _.get(value, 'FIRST NAME', ''),
      phoneNumber: _.get(value, 'PHONE NUMBER', ''),
      lastName: _.get(value, 'LAST NAME', ''),
      name:
        _.get(value, 'NAME', '') === ''
          ? `${_.get(value, 'FIRST NAME', '')} ${_.get(value, 'LAST NAME', '')}`
          : _.get(value, 'NAME', ''),
    });
    usedispatch(setManualSender(true));
  };

  return (
    <div
      style={{ height: 600, width: 'inherit', overflowY: 'auto', zIndex: 1 }}
    >
      <table className="table table-striped table-hover text-center">
        <thead>
          <tr>
            <th scope="col"></th>
            {tableHeaders.map((e, index) => (
              <th scope="col" key={e + index}>
                {e}
              </th>
            ))}
            <th scope="col">Send Manually</th>
          </tr>
        </thead>
        <tbody>
          {_.values(
            _.mapValues(people, (value, key) => {
              return (
                <tr key={key}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selected.includes(key)}
                      onChange={() => selectHandler(key)}
                    />
                  </td>
                  {tableHeaders.map((header, index) => {
                    return <td key={key + header + index}>{value[header]}</td>;
                  })}
                  <td>
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() => sendManualBtnHandler(value)}
                    >
                      Send Manually
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
