import React, { useReducer } from 'react';

import { ManualSenderContext } from './ManualSenderContext';
import { ManualSenderReducer } from './ManualSenderReducer';

export function ManualSenderProvider({ children }) {
  const [state, dispatch] = useReducer(ManualSenderReducer, {
    firstName: '',
    phoneNumber: '',
    lastName: '',
    name: '',
    WhatsAppSenderNumber: '',
    SMSSenderNumber: '',
  });

  return (
    <ManualSenderContext.Provider
      value={{
        phoneNumber: state.phoneNumber,
        firstName: state.firstName,
        lastName: state.lastName,
        name: state.name,
        WhatsAppSenderNumber: state.WhatsAppSenderNumber,
        SMSSenderNumber: state.SMSSenderNumber,
        updateManualSender: (type, value) =>
          dispatch({ type: type, payload: value }),
      }}
    >
      {children}
    </ManualSenderContext.Provider>
  );
}
