import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { setSelected } from '../../../Librarys/Redux/Slices/SenderSlices';
import { useContactProfile } from '../../../Librarys/Hooks/useContactProfile';
import { generateBatchUploadContacts } from '../../../Librarys/Utils/generateBatchUploadContacts';
import { addError } from '../../../Librarys/Redux/Slices/ErrorSlices';
import { editNameMapping } from '../../../Librarys/Redux/Slices/GlobalSlices';
import { ButtonWithLoading } from '../../../Librarys/Components';

export function SenderOptionHeader() {
  const { people, selected } = useSelector((state) => state.sender);
  const [saveContactProfile, , saveContactLoading] = useContactProfile();
  const usedispatch = useDispatch();

  const selectAllBtnHandler = () => {
    if (selected.length > 0) {
      usedispatch(setSelected([]));
    } else {
      const newSelected = [];
      for (const person in people) {
        newSelected.push(person);
      }
      usedispatch(setSelected(newSelected));
    }
  };

  const importBtnHandler = () => {
    const body = generateBatchUploadContacts(selected, people);

    if (body.length === 0) {
      alert(
        'Ubable to import, please check the contacts you selected have at least one of [NAME, FIRST NAME, LAST NAME] fields.'
      );
      return;
    }
    saveContactProfile(
      body,
      () => {
        usedispatch(editNameMapping(body));
        usedispatch(
          addError({
            type: 'info',
            message: `Contact Profile Saving Succeed on selected`,
            time: moment().format(),
          })
        );
      },
      () =>
        usedispatch(
          addError({
            type: 'error',
            message: `Contact Profile Saving Failed on selected`,
            time: moment().format(),
          })
        )
    );
  };
  return (
    <div>
      <hr />
      <div style={{ textAlign: 'end', marginBottom: '10px' }}>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          style={{ marginRight: '10px' }}
          onClick={selectAllBtnHandler}
        >
          Select All
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={importBtnHandler}
        >
          <ButtonWithLoading
            title="Import Selected Contacts Profiles"
            loading={saveContactLoading}
          />
        </button>
      </div>
    </div>
  );
}
