import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { MessageDateTime } from './MessageDateTime';
import { MessageTitle } from './MessageTitle';
import { MessageCard } from './MessageCard';
import { MessageInput } from './MessageInput';
import './ConversationMessages.css';

export function ConversationMessages() {
  const { selected, messages } = useSelector((state) => state.conversation);
  const { nameMappings } = useSelector((state) => state.nameMapping);

  useEffect(() => {
    if (
      document.getElementsByClassName('message-card-container').length !== 0 &&
      document.getElementsByClassName('message-card-container')[0]
        .lastElementChild
    )
      document
        .getElementsByClassName('message-card-container')[0]
        .lastElementChild.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        });
  }, [messages, selected]);

  const { message, phoneNumber, sendTool } = useMemo(() => {
    let message = [];
    let phoneNumber = 'Unknown';
    messages &&
      messages.forEach((m) => {
        if (m.phoneNumber === selected) {
          message = m.conversations;
          phoneNumber = m.phoneNumber;
        }
      });
    return {
      message,
      phoneNumber,
      sendTool: message.length > 0 ? _.last(message).type : 'SMS',
    };
  }, [messages, selected]);

  const generateMessage = (message) => {
    const renderList = [];
    let previousTime = 0;
    message.forEach((m) => {
      if (moment(previousTime).add(2, 'm').isBefore(moment(m.timestamp))) {
        renderList.push(
          <div key={m.timestamp}>
            <MessageDateTime
              week={moment(m.timestamp).format('dddd')}
              day={moment(m.timestamp).format('MM')}
              month={moment(m.timestamp).format('DD')}
              hour={moment(m.timestamp).format('hh')}
              min={moment(m.timestamp).format('mm')}
              ap={moment(m.timestamp).format('A')}
              year={moment(m.timestamp).format('YYYY')}
            ></MessageDateTime>
            <MessageCard messageDetail={m} phoneNumber={phoneNumber} />
          </div>
        );
      } else {
        renderList.push(
          <MessageCard
            key={m.timestamp}
            messageDetail={m}
            phoneNumber={phoneNumber}
          />
        );
      }
      previousTime = moment(m.timestamp).format();
    });
    return renderList;
  };

  return (
    <div className="d-flex flex-column message-container box-shadow-grey">
      <MessageTitle
        title={nameMappings[selected] ? nameMappings[selected] : selected}
        phoneNumber={selected}
      />
      <div className="px-2 py-3 message-card-container bg-image-daytime">{generateMessage(message)}</div>

      <div>
        <MessageInput sendTool={sendTool} phoneNumber={`+${phoneNumber}`} />
      </div>
    </div>
  );
}
