import React, { useEffect, useState, memo } from 'react';
import { ButtonGroup, Button, Dropdown, Spinner } from 'react-bootstrap';
import useFetch from 'use-http';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import './messageInput.css';
import { addError } from '../../../../Librarys/Redux/Slices/ErrorSlices';

export function MessageInput({ sendTool, phoneNumber }) {
  const { get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_SENDER_API,
    { cachePolicy: 'no-cache' }
  );
  const dispatch = useDispatch();
  const [tool, setTool] = useState(sendTool);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setTool(sendTool);
  }, [sendTool]);

  useEffect(() => {
    // Get the input field
    let input = document.getElementById('inputBox');

    // Execute a function when the user releases a key on the keyboard
    input.addEventListener('keyup', function (event) {
      let handled = false;
      if (event.key && event.key === 'Enter') {
        handled = true;
        // Handle the event with KeyboardEvent.key and set handled true.
      } else if (event.keyCode && event.keyCode === 13) {
        handled = true;
        // Handle the event with KeyboardEvent.keyCode and set handled true.
      }
      if (event.key === 'Enter') {
        handled = true;
      }

      if (handled) {
        event.preventDefault();
        document.getElementById('sendBtn').click();
      }
    });
  }, []);

  function generateRequestBody(type, phoneNumber, message) {
    switch (type) {
      case 'SMS':
        return { tos: [`+${Number(phoneNumber)}`], texts: [message] };
      case 'WHATSAPP':
        return { tos: [`+${Number(phoneNumber)}`], texts: [message] };
      default:
        break;
    }
  }

  async function sendSMS() {
    if (message.length === 0) {
      alert('The Message Box is Empty!');
      return;
    }

    const newMessage = await post(
      `/sms`,
      generateRequestBody('SMS', phoneNumber, message)
    );
    if (response.ok) {
      setMessage('');
    }
    if (error) {
      dispatch(
        addError({
          type: 'error',
          message:
            'Unable to send the message! Please make sure the conversation service is online and try again.',
          time: moment().format(),
        })
      );
    }
  }

  async function sendWhatsApp() {
    if (message.length === 0) {
      alert('The Message Box is Empty!');
      return;
    }

    const newMessage = await post(
      `/whatsapp`,
      generateRequestBody('WHATSAPP', phoneNumber, message)
    );
    if (response.ok) {
      setMessage('');
    }
    if (error) {
      dispatch(
        addError({
          type: 'error',
          message:
            'Unable to send the message! Please make sure the conversation service is online and try again.',
          time: moment().format(),
        })
      );
    }
  }

  function sendBtnHandler() {
    if (tool === 'SMS') {
      sendSMS();
    } else {
      sendWhatsApp();
    }
  }

  return (
    <div className='px-3 message-input-container'>
      <div className="d-flex flex-row">
        <input
          type="text"
          id="inputBox"
          className="input-box"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Dropdown as={ButtonGroup}>
          <Button
            className="conversation-send-btn"
            variant={_.toLower(tool)}
            id="sendBtn"
            onClick={sendBtnHandler}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              tool
            )}
          </Button>

          <Dropdown.Toggle
            className="conversation-send-dropdown-toggle"
            variant={_.toLower(tool)}
            disabled={loading}
            split
            id="dropdown-split-basic"
          />

          <Dropdown.Menu>
            <Dropdown.Item onClick={(e) => setTool('WhatsApp')}>
              WhatsApp
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => setTool('SMS')}>SMS</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
