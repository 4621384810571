import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import useFetch from 'use-http';
import moment from 'moment';

import { addError } from '../../Redux/Slices/ErrorSlices';
import { addMessage, setWebSocket } from '../../Redux/Slices/ConversationSlice';
import { Loading, SinglePageContainer } from '../../Components';

export function WebSocketBoundary({ children }) {
  const socketUrl = process.env.REACT_APP_WS_API;

  const websocketStatus = useSelector((state) => state.conversation.websocket);
  const usedispatch = useDispatch();
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const { get, post, response, loading, error, request } = useFetch(
    process.env.REACT_APP_API,
    { cachePolicy: 'no-cache' }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'connecting',
    [ReadyState.OPEN]: 'online',
    [ReadyState.CLOSING]: 'offline',
    [ReadyState.CLOSED]: 'offline',
    [ReadyState.UNINSTANTIATED]: 'offline',
  }[readyState];

  useEffect(() => {
    if (connectionStatus === 'offline') {
      usedispatch(
        addError({
          type: 'error',
          message: `Unable to connect to WebSocket on ${socketUrl}`,
          time: moment().format(),
        })
      );
      usedispatch(setWebSocket(false));
    }
  }, [readyState]);

  async function getSingleConversations(number) {
    const conversation = await get(`/conversation?phoneNumber=${number}`);
    if (response.ok) {
      usedispatch(addMessage({ conversation: conversation[0], number }));
    }
    if (error) {
      usedispatch(
        addError({
          type: 'error',
          message: 'unable to get data from ws server',
          time: moment().format(),
        })
      );
      usedispatch(setWebSocket(false));
    }
  }
  useEffect(() => {
    if (
      lastMessage &&
      lastMessage.data !== 'connect to express server with WebSocket success'
    ) {
      setTimeout(() => {
        getSingleConversations(lastMessage.data);
      }, 1000);
    }

    if (
      lastMessage &&
      lastMessage.data === 'connect to express server with WebSocket success'
    ) {
      usedispatch(
        addError({
          type: 'info',
          message: `Connected to WebSocket on ${socketUrl}`,
          time: moment().format(),
        })
      );
      usedispatch(setWebSocket(true));
    }
  }, [lastMessage]);
  return (
    <>
      {connectionStatus === 'connecting' ? (
        <SinglePageContainer>
          <Loading />
        </SinglePageContainer>
      ) : (
        children
      )}
    </>
  );
}
