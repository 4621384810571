import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Stack } from 'react-bootstrap';

import styles from './Loading.module.scss';

export function Loading({ context }) {
  return (
    <Stack
      className={`justify-content-center align-items-center ${styles.stack}`}
    >
      <Spinner
        animation="border"
        variant="primary"
        className={styles.spinnerSize}
      />
      <h2>{'Loading...'}</h2>
    </Stack>
  );
}

Loading.propTypes = {
  context: PropTypes.node,
};
