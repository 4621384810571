import useFetch from 'use-http';
export const useConversation = () => {
  const { get, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    { cachePolicy: 'no-cache' }
  );

  async function getConversation(onSuccess, onError) {
    const conversation = await get('/conversation');
    if (response.ok) {
      onSuccess && onSuccess(conversation);
    }
    if (error) {
      onError && onError();
    }
  }

  return [getConversation, loading];
};
