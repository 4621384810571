import useFetch from 'use-http';
export const useSMSTemplateSender = () => {
  const { request, post, response, loading, error } = useFetch(
    process.env.REACT_APP_SENDER_API,
    { cachePolicy: 'no-cache' }
  );
  async function sendSMS(body, onSuccess, onError, onFinished) {
    await post('/sms-t', body);
    if (response.ok) {
      if (onSuccess !== null) {
        onSuccess();
      }
    }
    if (error) {
      onError && onError();
    }
  }
  return [sendSMS, loading];
};
