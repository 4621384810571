import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openManualSender: false,
};

export const ManualSenderSlice = createSlice({
  name: 'manualSender',
  initialState,
  reducers: {
    setManualSender: (state, action) => {
      state.openManualSender = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setManualSender } = ManualSenderSlice.actions;

export default ManualSenderSlice.reducer;
