import { useDispatch } from 'react-redux';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

import { setToken } from '../../Redux/Slices/AuthenicationSlices';

export function Navigation() {
  const path = useLocation().pathname;
  const dispatch = useDispatch();
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#">SMS & WhatsApp Sender</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              className={`nav-link ${path === '/sender' || path === '/' ? 'active' : ''}`}
              activeClassName="is-active"
              to="/sender"
              exact
            >
              Sender
            </NavLink>
            <NavLink
              className={`nav-link ${path === '/conversation' ? 'active' : ''}`}
              activeClassName="is-active"
              to="/conversation"
              exact
            >
              Conversation
            </NavLink>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <a href="#/" onClick={() => dispatch(setToken(null))}>
              Log out
            </a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
