import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { setToken } from '../../Redux/Slices/AuthenicationSlices';
import { useAuthorization } from '../../Hooks/useAuthorization';
import { ButtonWithLoading } from '../ButtonWithLoading';
import { logIn } from '../../Utils/authenication';
import './login.css';
import { useDispatch, useSelector } from 'react-redux';

export function AuthenicationBoundary({ children }) {
  const storedToken = useSelector((state) => state.auth.token);
  const usedispatch = useDispatch();
  const useAuth = useCallback(
    () => useAuthorization(storedToken || ''),
    [storedToken]
  );
  const [login, verify, loading] = useAuth();
  const [isLogin, setLogin] = useState(false);
  const [creditWrong, setCreditWrong] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (storedToken && !isLogin) {
      verify(
        () => setLogin(true),
        () => {
          usedispatch(setToken(null));
          setLogin(false);
        }
      );
    } else {
      if (!storedToken && isLogin) setLogin(false);
    }
  }, [storedToken]);

  const loginHandler = () => {
    login(
      {
        username: username,
        password: password,
      },
      (token) => {
        logIn(token);
        setLogin(true);
        usedispatch(setToken(token));
      },
      () => {
        setCreditWrong(true);
      }
    );
  };

  return (
    <>
      {!storedToken && !isLogin && (
        <div className="login">
          <main className="form-signin">
            <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
            {creditWrong && (
              <div className="alert alert-danger" role="alert">
                Wrong Username & Password!
              </div>
            )}
            <div className="form-floating">
              <input
                type="username"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label htmlFor="floatingInput">Username</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="floatingPassword">Password</label>
            </div>
            <button
              className="w-100 btn btn-lg btn-primary"
              onClick={loginHandler}
            >
              <ButtonWithLoading
                title="Sign in"
                loading={loading}
              ></ButtonWithLoading>
            </button>
            <p className="mt-5 mb-3 text-muted">&copy; 2021–2021</p>
          </main>
        </div>
      )}
      {storedToken && isLogin && children}
    </>
  );
}

AuthenicationBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
