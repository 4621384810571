import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { SinglePageContainer, Loading } from './Librarys/Components';
import { App as SenderApp } from './Applications/Sender/App';
import { App as ConversationApp } from './Applications/Conversations/App';
import { useConversation } from './Librarys/Hooks/useConversation';
import { useContactProfile } from './Librarys/Hooks/useContactProfile';
import { setNameMappings } from './Librarys/Redux/Slices/GlobalSlices';
import { addError } from './Librarys/Redux/Slices/ErrorSlices';
import { setMessages } from './Librarys/Redux/Slices/ConversationSlice';

export default function App() {
  const { nameMappings } = useSelector((state) => state.nameMapping);
  const { messages } = useSelector((state) => state.conversation);
  const [, getContactProfiles, getContactLoading] = useContactProfile();
  const usedispatch = useDispatch();
  const [getConversation, getConversationLoading] = useConversation();

  const initializeContacts = () => {
    getContactProfiles(
      (contacts) => {
        const storedNames = {};
        contacts.forEach((element) => {
          storedNames[element.phoneNumber] = element.name
            ? element.name
            : element.firstName + ' ' + element.lastName;
        });
        usedispatch(setNameMappings(storedNames));
      },
      () => {
        usedispatch(
          addError({
            type: 'Error',
            message: 'Unable to get stored contact profiles.',
            time: moment().format(),
          })
        );
      }
    );
  };

  const initializeConversation = () => {
    getConversation(
      (conversation) => {
        usedispatch(setMessages(conversation));
      },
      () => {
        usedispatch(
          addError({
            type: 'error',
            message: 'Unable to get stored conversation',
            time: moment().format(),
          })
        );
      }
    );
  };

  useEffect(() => {
    initializeContacts();
    initializeConversation();
  }, []);

  if (nameMappings === {} || messages === null) {
    return (
      <SinglePageContainer>
        <Loading />
      </SinglePageContainer>
    );
  }
  return (
    <Container style={{ height: '100%' }}>
      <Router>
        <Switch>
          <Route path="/sender" exact>
            <SenderApp />
          </Route>
          <Route path="/conversation" exact>
            <ConversationApp />
          </Route>
          <Route path="/" exact>
            <SenderApp />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
}
