import { createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  messages: null,
  selected: null,
  websocket: false,
  showReplied: true,
};

const sortDataByUpdateDate = (data, cb) => {
  data.sort(function compareFn(firstEl, secondEl) {
    const date1 = _.get(firstEl, 'lastUpdated', '01-01-1970');
    const date2 = _.get(secondEl, 'lastUpdated', '01-01-1970');
    if (date1 > date2) {
      return -1;
    } else if (date1 < date2) {
      return 1;
    }
    return 0;
  });
  if (cb) {
    return cb(data);
  }
  return data;
};

const countNotification = (data) => {
  return data.map((element) => {
    const conversations = _.get(element, 'conversations', []);
    let count = 0;
    conversations.forEach((conversation) => {
      const read = _.get(conversation, 'read', false);
      const direction = _.get(conversation, 'direction', 'outgoing');
      if (read === false && direction == 'incoming') count++;
    });
    element['notification'] = count;
    return element;
  });
};

const readMessage = (data, number) => {
  return data.map((element) => {
    const phoneNumber = _.get(element, 'phoneNumber', []);
    if (phoneNumber === number) {
      element = {
        ...element,
        notification: 0,
      };
    }
    return element;
  });
};

const addConversation = (messages, conversation, number) => {
  return messages.map((element) => {
    const phoneNumber = _.get(element, 'phoneNumber', []);
    if (phoneNumber === number) {
      element = {
        ...conversation,
      };
    }
    return element;
  });
};

const countSingleConversationNotification = (
  messages,
  conversation,
  number,
  cb
) => {
  let count = 0;
  conversation.conversations.forEach((conversation) => {
    const read = _.get(conversation, 'read', false);
    const direction = _.get(conversation, 'direction', 'outgoing');
    if (read === false && direction == 'incoming') count++;
  });
  conversation['notification'] = count;
  return cb(messages, conversation, number);
};

export const ConversationSlice = createSlice({
  name: 'Sender',
  initialState,
  reducers: {
    setWebSocket: (state, action) => {
      state.websocket = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = [
        ...sortDataByUpdateDate(action.payload, countNotification),
      ];
    },
    removeMessages: (state, action) => {
      state.messages = null;
    },
    removeNotification(state, action) {
      state.messages = [
        ...readMessage(current(state.messages), action.payload),
      ];
    },
    setConversationSelected: (state, action) => {
      state.selected = action.payload;
    },
    addMessage: (state, action) => {
      state.messages = [
        ...sortDataByUpdateDate(
          countSingleConversationNotification(
            current(state.messages),
            action.payload.conversation,
            action.payload.number,
            addConversation
          )
        ),
      ];
    },
    showRepliedHandler: (state, action) => {
      state.showReplied = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setWebSocket,
  setMessages,
  removeMessages,
  removeNotification,
  setConversationSelected,
  addMessage,
  showRepliedHandler,
} = ConversationSlice.actions;

export default ConversationSlice.reducer;
