import React from 'react';

export const InputGroup = ({
  labelName,
  id,
  value,
  onChange,
  placeholder = '',
}) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {labelName}
      </label>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
