import React from 'react';
import { Alert } from 'react-bootstrap';
export const InformationPad = () => {
  return (
    <Alert variant="success">
      <Alert.Heading>File Constraints</Alert.Heading>
      <p>Columns: Contact File must include a column of [Phone Number].</p>
      <p>Rows: Empty row and duplicate row will be droped.</p>
      <p>Headers: All Headers will be converted into uppercase.</p>
      <hr />
      <Alert.Heading>3/26/2022 Updates:</Alert.Heading>
      <p className="mb-0">
        <p>1. !!! New authorization strategy.</p>
        <p>
          2. !!! Now with the new filter on conversation, you choose to show all
          conversation or only replied conversation.
        </p>
        <p>3. Applied customize theme.</p>
      </p>
      <hr />
      <Alert.Heading>Previous Updates:</Alert.Heading>
      <p className="mb-0">
        <p>
          1. !!! Now with new notification feature which will be located at
          top-right of the page.
        </p>
        <p>
          2. !!! Now with send with progress feature which will show a progress
          bar during sending.
        </p>
        <p>
          3. !!! Now with new application UI core, you can switch between sender
          and conversation with no lagging and loading.
        </p>
        <p>
          4. !!! Now with new contact profile saving and editing feature, you
          can edit or add contact name in conversation or manually send modal or
          save selected contact button upon contact table.
        </p>
        <p>5. Removed Send All Button.</p>
        <p>6. Removed all unnecessary decoration.</p>
        <p>7. Moved Send Manually to the top of the sender page.</p>
        <p>
          8. Upon request, manual send feature now sending template message.
        </p>
      </p>
      {/* <hr />
      <Alert.Heading>Previous Updates:</Alert.Heading>
      <p className="mb-0">
        <p>
          !!! Now imported files with phone number, name (or first name and last
          name) fields can be imported into name mappings.
        </p>
      </p> */}
    </Alert>
  );
};
