import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  tableHeaders: [],
  people: null,
  selected: [],
};

export const SenderSlice = createSlice({
  name: 'Sender',
  initialState,
  reducers: {
    initial: (state, action) => {
      state.tableHeaders = [...action.payload.tableHeaders];
      state.people = { ...action.payload.people };
    },
    clear: (state) => {
      state.tableHeaders = [];
      state.people = null;
      state.SMSSelected = [];
      state.WhatsAppSelected = [];
    },
    setSelected: (state, action) => {
      state.selected = [...action.payload];
    },
    editSelected: (state, action) => {
      if (current(state.selected).includes(action.payload)) {
        const newSelected = current(state.selected).filter(
          (s) => s !== action.payload
        );
        state.selected = [...newSelected];
      } else {
        state.selected = [action.payload, ...current(state.selected)];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { initial, clear, setSelected, editSelected } =
  SenderSlice.actions;

export default SenderSlice.reducer;
