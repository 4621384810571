import { configureStore } from '@reduxjs/toolkit';

import nameMappingReducer from './Slices/GlobalSlices';
import ErrorReducer from './Slices/ErrorSlices';
import AuthenicationReducer from './Slices/AuthenicationSlices';
import SenderReducer from './Slices/SenderSlices';
import BatchSenderReducer from './Slices/BatchSenderSlice';
import ConversationReducer from './Slices/ConversationSlice';
import ManualSenderReducer from './Slices/ManualSenderSlices';

export const GlobalStore = configureStore({
  reducer: {
    nameMapping: nameMappingReducer,
    error: ErrorReducer,
    auth: AuthenicationReducer,
    sender: SenderReducer,
    batchSender: BatchSenderReducer,
    conversation: ConversationReducer,
    manualSender: ManualSenderReducer,
  },
});
