import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { Navigation, ThemeProvider } from '../../Librarys/Components';
import { ConversationLayout } from './ConversationLayout';
import { ConversationList } from './ConversationList';
import { ConversationMessages } from './ConversationMessages';
import { cobrands } from '../Cobrands';

export function App() {
  const cobrand = cobrands[process.env.REACT_APP_COBRAND];
  const cobrandPrefixes = cobrand.prefixes;
  const CobrandStyles = cobrand.styles;

  const { selected, websocket } = useSelector((state) => state.conversation);

  return (
    <ThemeProvider
      StylesComponent={<CobrandStyles />}
      cobrand={cobrandPrefixes}
    >
      <Navigation />
      {!websocket && (
        <Alert variant="danger" className="mt-2">
          Real Time Serive is down, you cannot receive lastest messages
          currently.
        </Alert>
      )}
      <ConversationLayout>
        <div className="col-4">
          <ConversationList />
        </div>
        <div className="col-8">{selected && <ConversationMessages />}</div>
      </ConversationLayout>
    </ThemeProvider>
  );
}
