export function templateMessageChecker(value, setFlag, setMessage, options) {
  setFlag(false);
  let regex = new RegExp('{{(.*?)}}', 'g');
  let selected = [];
  while (true) {
    const x = regex.exec(value);
    if (x) {
      if (options.includes(x[1])) {
        selected.push(x[1]);
      } else {
        setFlag(true);
      }
    } else break;
  }
  setMessage(value);
  return selected;
}
