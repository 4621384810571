import { createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  errors: [],
};

export const ErrorSlice = createSlice({
  name: 'Error',
  initialState,
  reducers: {
    addError: (state, action) => {
      state.errors = [action.payload, ...state.errors];
    },
    removeError: (state, action) => {
      const errorTime = action.payload.time;
      const newErrors = [];
      current(state.errors).forEach((error) => {
        if (error.time !== errorTime) {
          newErrors.push(error);
        }
      });
      state.errors = [...newErrors];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addError, removeError } = ErrorSlice.actions;

export default ErrorSlice.reducer;
