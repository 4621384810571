import React from 'react';

export function ConversationTitle({ showReplied, HandleshowReplied }) {
  return (
    <div className="border-bottom">
      <span className="d-flex align-items-center flex-shrink-0 px-3 pt-3 link-dark text-decoration-none position-relative">
        <span className="fs-5 fw-semibold">Conversation</span>
      </span>
      <div>
        <span className="d-flex align-items-center flex-shrink-0 px-3 mb-2 link-dark text-decoration-none position-relative">
          <small>
            <strong>{`Show ${showReplied? 'Replied' : 'All'} Conversation`}</strong>
          </small>
          <div className="position-absolute top-50 end-0 translate-middle-y">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={showReplied}
                onChange={HandleshowReplied}
              />
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}
