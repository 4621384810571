import React, { useState, useEffect } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import useFetch from 'use-http';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import './MessageTitle.css';
import { editNameMapping } from '../../../../Librarys/Redux/Slices/GlobalSlices';
import { addError } from '../../../../Librarys/Redux/Slices/ErrorSlices';

export function MessageTitle({ title, phoneNumber }) {
  const [edit, setEdit] = useState(false);
  const [nameInput, setNameInput] = useState(title);

  useEffect(() => {
    setEdit(false);
    setNameInput(title);
  }, [title, phoneNumber]);
  const usedispatch = useDispatch();
  const { get, post, response, request, loading, error } = useFetch(
    process.env.REACT_APP_API,
    {
      cachePolicy: 'no-cache',
    }
  );

  async function changeName() {
    await post('/name/add', [{ phoneNumber: phoneNumber, name: nameInput }]);
    if (response.ok) {
      usedispatch(
        editNameMapping([{ phoneNumber: phoneNumber, name: nameInput }])
      );
      usedispatch(
        addError({
          type: 'info',
          message: 'Name Change Operation Saved',
          time: moment().format(),
        })
      );
    }
    if (error) {
      usedispatch(
        addError({
          type: 'error',
          message: 'unable to change name',
          time: moment().format(),
        })
      );
    }
    setEdit(false);
  }

  return (
    <div className='p-3 message-title-container'>
      {!edit && (
        <span className="fs-5 fw-semibold">
          {title}
          <FaUserEdit
            className="user-edit-wahtsapp-icon fs-5 fw-semibold"
            onClick={() => setEdit(true)}
          />
        </span>
      )}
      {edit && (
        <InputGroup>
          <FormControl
            placeholder={title}
            value={nameInput}
            onChange={(e) => {
              setNameInput(e.target.value);
            }}
          />
          <Button
            variant="outline-secondary"
            id="button-addon2"
            onClick={() => changeName()}
            disabled={loading}
          >
            Save
          </Button>
          <Button
            variant="outline-secondary"
            id="button-addon2"
            onClick={() => setEdit(false)}
          >
            Cancel
          </Button>
        </InputGroup>
      )}
    </div>
  );
}
