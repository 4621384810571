import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  nameMappings: {},
};

export const nameMappingSlice = createSlice({
  name: 'nameMapping',
  initialState,
  reducers: {
    setNameMappings: (state, action) => {
      state.nameMappings = { ...action.payload };
    },
    // TODO: add delete
    deleteNameMapping: (state, action) => {},
    editNameMapping: (state, action) => {
      const oldNameMappings = { ...current(state.nameMappings) };
      action.payload.forEach((contact) => {
        oldNameMappings[contact.phoneNumber] = contact.name;
      });
      state.nameMappings = {
        ...oldNameMappings,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNameMappings, deleteNameMapping, editNameMapping } =
  nameMappingSlice.actions;

export default nameMappingSlice.reducer;
