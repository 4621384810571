import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  SMSSenderNumber: '',
  WhatsAppSenderNumber: '',
};

export const BatchSenderSlice = createSlice({
  name: 'BatchSender',
  initialState,
  reducers: {
    setSMSSender: (state, action) => {
      state.SMSSenderNumber = action.payload;
    },
    setWhatsAppSender: (state, action) => {
      state.WhatsAppSenderNumber = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSMSSender, setWhatsAppSender } = BatchSenderSlice.actions;

export default BatchSenderSlice.reducer;
