import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from 'use-http';
import _ from 'lodash';

import './ConversationCard.css';
import {
  setConversationSelected,
  removeNotification,
} from '../../../../Librarys/Redux/Slices/ConversationSlice';
import { addError } from '../../../../Librarys/Redux/Slices/ErrorSlices';

export function ConversationCard({ message }) {
  const { get, post, response, loading, error, request } = useFetch(
    process.env.REACT_APP_API,
    { cachePolicy: 'no-cache' }
  );
  const { nameMappings } = useSelector((state) => state.nameMapping);
  const dispatch = useDispatch();
  const getLatestMessageDate = (d) => {
    const dMoment = moment(d);
    const nMoment = moment();
    // today
    if (dMoment.year() === nMoment.year()) {
      if (dMoment.dayOfYear() === nMoment.dayOfYear()) {
        return dMoment.format('hh:mm A');
      }
      // yesterday
      if (dMoment.dayOfYear() === nMoment.dayOfYear() - 1) {
        return 'Yesterday';
      }
      // (-2, -6)
      if (
        dMoment.dayOfYear() <= nMoment.dayOfYear() - 2 &&
        dMoment.dayOfYear() >= nMoment.dayOfYear() - 6
      ) {
        return dMoment.format('dddd');
      }
    }

    return dMoment.format('MM/DD/YY');
  };

  const { selected } = useSelector((state) => state.conversation);

  const { number, notification, latestDay, latestMessage } = useMemo(() => {
    const number = message.phoneNumber;
    const notification = message.notification;
    const latestConversation = _.last(_.get(message, 'conversations'));
    const latestDay = getLatestMessageDate(
      _.get(latestConversation, 'timestamp')
    );
    const latestMessage = _.get(latestConversation, 'body.body');
    return { number, notification, latestDay, latestMessage };
  }, [message]);

  async function readMessage() {
    await post('/conversation/read', [number]);
    if (response.ok) {
      dispatch(removeNotification(number));
    }
    if (error) {
      dispatch(
        addError({
          type: 'error',
          message: process.env.REACT_APP_SENDER_API + '/conversation/read',
          time: moment().format(),
        })
      );
    }
  }
  function btnClickHandler() {
    if (notification > 0) {
      readMessage();
    }
    dispatch(setConversationSelected(number));
  }

  return (
    <button
      className={`list-group-item list-group-item-action ${
        selected === number ? 'active' : ''
      } py-3 lh-tight conversation-btn`}
      onClick={btnClickHandler}
    >
      <div className="d-flex w-100 align-items-center justify-content-between">
        <span>
          <strong className="mb-1">
            {' '}
            {`${nameMappings[number] ? nameMappings[number] : number} `}
            {notification === 0 ? null : (
              <>
                <span className="badge bg-danger rounded-circle">
                  {notification}
                </span>
                {' '}
                <small style={{ fontSize: '12px' }}>{`Unread Message${
                  notification <= 1 ? '' : 's'
                }`}</small>
              </>
            )}
          </strong>
          <br />
        </span>
        <small>{latestDay}</small>
      </div>
      <div className="col-10 mb-1 small lastMessage">{latestMessage}</div>
    </button>
  );
}
