import React from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaSms } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import './messageCard.css';

export function MessageCard({ messageDetail, phoneNumber }) {
  const pos = messageDetail.direction === 'incoming' ? 'start' : 'end';
  const messageType = messageDetail.type;
  const message = messageDetail.body.body;

  const { nameMappings } = useSelector((state) => state.nameMapping);

  return (
    <div className={`d-flex justify-content-${pos}`}>
      {pos === 'start' ? (
        <>
          <span className="message-text">
            {nameMappings[phoneNumber]
              ? nameMappings[phoneNumber]
              : phoneNumber}
          </span>
          <span className={`text-box-${pos} bg-incoming position-relative`}>
            {message}
            {messageType === 'Whatsapp' ? (
              <IoLogoWhatsapp
                className="position-absolute p-1 top-0 start-100 translate-middle border border-light rounded-circle bg-whatsapp"
                color="white"
                size="1.8rem"
              />
            ) : (
              <FaSms
                className="position-absolute top-0 start-100 translate-middle border p-1 border-light rounded-circle bg-sms"
                color="white"
                size="1.8rem"
              />
            )}
          </span>
        </>
      ) : (
        <>
          {messageType === 'Whatsapp' ? (
            <span className={`text-box-${pos} bg-whatsapp`}>{message}</span>
          ) : (
            <span className={`text-box-${pos} bg-sms`}>{message}</span>
          )}
        </>
      )}
    </div>
  );
}
