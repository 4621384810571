import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'react-bootstrap';

import './index.css';
import App from './App';
import { GlobalStore } from './Librarys/Redux/GlobalStore';
import { ErrorBoundary, AuthenicationBoundary } from './Librarys/Components';
import { WebSocketBoundary } from './Librarys/Conversations';

ReactDOM.render(
  <Provider store={GlobalStore}>
    <AuthenicationBoundary>
      <ErrorBoundary>
        <WebSocketBoundary>
          <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
          >
            <App />
          </ThemeProvider>
        </WebSocketBoundary>
      </ErrorBoundary>
    </AuthenicationBoundary>
  </Provider>,
  document.getElementById('root')
);
