import React, { useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { showRepliedHandler } from '../../../Librarys/Redux/Slices/ConversationSlice';
import './ConversationList.css';
import { Loading } from '../../../Librarys/Components';
import { ConversationTitle } from './ConversationTitle';
import { ConversationCard } from './ConversationCard';

export function ConversationList() {
  const { messages, showReplied } = useSelector((state) => state.conversation);
  const usedispatch = useDispatch();

  const HandleshowReplied = () => {
    usedispatch(showRepliedHandler(!showReplied));
  };

  const conversationCardList = useMemo(() => {
    return (
      messages &&
      messages.map((element) => {
        if (showReplied) {
          if (
            _.last(_.get(element, 'conversations')).direction !== 'outgoing'
          ) {
            return (
              <ConversationCard message={element} key={_.get(element, '_id')} />
            );
          }
        } else {
          return (
            <ConversationCard message={element} key={_.get(element, '_id')} />
          );
        }
      })
    );
  }, [messages, showReplied]);

  return (
    <div className="d-flex flex-column align-items-stretch bg-white side-container max-height">
      <ConversationTitle
        showReplied={showReplied}
        HandleshowReplied={HandleshowReplied}
      />
      <div className="list-group list-group-flush border-bottom scrollarea">
        {!messages && <Loading></Loading>}
        {conversationCardList}
      </div>
    </div>
  );
}
