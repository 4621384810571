import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSMSSender,
  setWhatsAppSender,
} from '../../../Librarys/Redux/Slices/BatchSenderSlice';

export function SenderOptionFooter() {
  const { SMSSenderNumber, WhatsAppSenderNumber } = useSelector(
    (state) => state.batchSender
  );
  const dispatch = useDispatch();

  return (
    <div className="d-flex justify-content-between">
      <div className="input-group mb-3" style={{ marginRight: '40px' }}>
        <span className="input-group-text" id="SMS_sender">
          SMS Sender Number
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="eg.+12345678999 (Optional)"
          aria-label="SMS Sender Number"
          value={SMSSenderNumber}
          onChange={(e) => dispatch(setSMSSender(e.target.value))}
        />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="whatsapp_sender">
          WhatsApp Sender Number
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="eg.+12345678999 (Optional)"
          aria-label="WhatsApp Sender Number"
          value={WhatsAppSenderNumber}
          onChange={(e) => dispatch(setWhatsAppSender(e.target.value))}
        />
      </div>
    </div>
  );
}

export default SenderOptionFooter;
