import React from 'react';

const JosephStyles = () => {
  return (
    <style type="text/css">
      {`
            .conversation-send-btn{
                box-shadow: 0px 0px 6px 0px gray;
                border-radius:9999rem;
                margin: 20px 0px 10px 15px;
                border: none;
                padding: 0.5rem 1em 0.5rem 1rem;
                color: white;
                width: 6rem;
            }
            .conversation-send-dropdown-toggle {
                box-shadow: 0px 0px 6px 0px gray;
                border-radius:9999rem;
                margin: 20px 0px 10px 15px;
                border: none;
                width: 1rem;
                color: white;
                padding: 0.5rem 2rem 0.5rem 0.25rem;
            }
            .bg-whatsapp,
            .btn-whatsapp{
                background-color: #53d769;
            }
            .bg-sms,
            .btn-sms {
                background-color: #067dfe;
            }
            .text-whatsapp {
                color: #53d769;
            }
            .text-sms {
                color: #067dfe;
            }
            .bg-incoming {
                background-color: #e9e9eb;
                color: black;
            }
            .box-shadow-white {
                box-shadow: 0px 0px 6px 0px white;
            }
            .box-shadow-grey {
                box-shadow: 0px 0px 6px 0px grey;
            }
            .bg-image-daytime {
                background-image: url("./background-image/day.png")
            }
      `}
    </style>
  );
};

export const cobrands = {
  joseph: {
    prefixes: {},
  },
};

cobrands.joseph.styles = JosephStyles;
