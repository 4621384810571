import { createSlice } from '@reduxjs/toolkit';
import { logOut } from '../../Utils';
const initialState = {
  token: window.localStorage['token'],
};

export const AuthenicationSlice = createSlice({
  name: 'Authenication',
  initialState,
  reducers: {
    setToken: (state, action) => {
      if (action.payload) {
        state.token = action.payload;
      } else {
        state.token = action.payload;
        logOut();
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken } = AuthenicationSlice.actions;

export default AuthenicationSlice.reducer;
