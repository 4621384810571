import React from 'react';
import { Spinner } from 'react-bootstrap';

export function ButtonWithLoading({ title, loading }) {
  return (
    <>
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        title
      )}
    </>
  );
}
