export function ManualSenderReducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        firstName: action.payload.firstName,
        phoneNumber: action.payload.phoneNumber,
        lastName: action.payload.lastName,
        name: action.payload.name,
        WhatsAppSenderNumber: '',
        SMSSenderNumber: '',
      };
    case 'name':
      return { ...state, name: action.payload };
    case 'firstName':
      return { ...state, firstName: action.payload };
    case 'lastName':
      return { ...state, lastName: action.payload };
    case 'phoneNumber':
      return { ...state, phoneNumber: action.payload };
    case 'WhatsAppSenderNumber':
      return { ...state, WhatsAppSenderNumber: action.payload };
    case 'SMSSenderNumber':
      return { ...state, SMSSenderNumber: action.payload };
    case 'clear':
      return {
        firstName: '',
        phoneNumber: '',
        lastName: '',
        name: '',
        WhatsAppSenderNumber: '',
        SMSSenderNumber: '',
      };
    default:
      return state;
  }
}
