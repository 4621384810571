import useFetch from 'use-http';

export const useAuthorization = (token = '') => {
  const { request, get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_API,
    {
      cachePolicy: 'no-cache',
      headers: {
        token: token,
      },
    }
  );
  async function login(body, onSuccess, onError) {
    console.log('login');
    const { token } = await post('/auth/login', body);
    if (response.ok) {
      onSuccess && onSuccess(token);
    }
    if (!response.ok) {
      onError && onError();
    }
  }

  async function verify(onSuccess, onError, finial) {
    console.log('verify');
    await get('/auth/me');
    if (response.ok) {
      onSuccess && onSuccess();
    }
    if (!response.ok) {
      onError && onError();
    }
    finial && finial();
  }

  return [login, verify, loading];
};
