import React from 'react';
import { useSelector } from 'react-redux';

import {
  Navigation,
  FileUpload,
  InformationPad,
} from '../../Librarys/Components';

import { ManualSenderModal } from './ManualSenderModal';
import { ManualSenderProvider } from './ManualSenderContext';
import { BatchSenderTools } from './BatchSenderTools';
import { SenderOptionHeader } from './SenderOptionHeader';
import { ContactTable } from './ContactTable';
import { SenderOptionFooter } from './SenderOptionFooter';

export function App() {
  const people = useSelector((state) => state.sender.people);

  return (
    <>
      <Navigation />
      <ManualSenderProvider>
        <FileUpload />
        {people && (
          <>
            <SenderOptionHeader />
            <ContactTable />
            <SenderOptionFooter />
            <BatchSenderTools sendFrequency={Number.MAX_SAFE_INTEGER} />
          </>
        )}
        {!people && <InformationPad />}
        <ManualSenderModal />
      </ManualSenderProvider>
    </>
  );
}
