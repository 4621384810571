import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ProgressBar } from 'react-bootstrap';

import styles from './BatchSenderTools.module.scss';
import { useSMSTemplateSender } from '../../../Librarys/Hooks/useSMSTemplateSender';
import { useWhatsAppTemplateSender } from '../../../Librarys/Hooks/useWhatsAppTemplateSender';
import { templateMessageChecker } from '../../../Librarys/Utils';
import { generateBatchSenderBody } from '../../../Librarys/Utils';
import { addError } from '../../../Librarys/Redux/Slices/ErrorSlices';

export function BatchSenderTools({ sendFrequency }) {
  const { tableHeaders, selected, people } = useSelector(
    (state) => state.sender
  );
  const { SMSSenderNumber, WhatsAppSenderNumber } = useSelector(
    (state) => state.batchSender
  );
  const usedispatch = useDispatch();
  const selectedHeaders = useRef([]);

  const [progress, setProgress] = useState(-1);
  const [message, setMessage] = useState('');
  const [resveredFlag, setResveredFlag] = useState(false);

  const [sendSMS, SMSLoading] = useSMSTemplateSender();
  const [sendWhatsApp, WhatsAppLoading] = useWhatsAppTemplateSender();

  const handleMessageChange = (value) => {
    const newSelected = templateMessageChecker(
      value,
      setResveredFlag,
      setMessage,
      tableHeaders
    );
    selectedHeaders.current = [...newSelected];
  };

  function sendRequirementCheck() {
    if (message === '' || selected.length === 0) {
      alert('Please fill required fields.');
      return false;
    }
    return true;
  }

  async function SMSBtnHandler() {
    if (!sendRequirementCheck()) {
      return;
    }
    setProgress(0);
    let start = 0;
    while (start < selected.length) {
      let sendQueue = selected.slice(start, start + sendFrequency);
      await SMSSendByQueue(sendQueue);
      start = start + sendFrequency;
    }
    usedispatch(
      addError({
        type: 'info',
        message: `SMS Batch Send Finished.`,
        time: moment().format(),
      })
    );
  }

  async function WhatsAppBtnHandler() {
    if (!sendRequirementCheck()) {
      return;
    }
    setProgress(0);
    let start = 0;
    while (start < selected.length) {
      let sendQueue = selected.slice(start, start + sendFrequency);
      await WhatsAppSendByQueue(sendQueue, start);
      start = start + sendFrequency;
    }
    usedispatch(
      addError({
        type: 'info',
        message: `WhatsApp Batch Send Finished.`,
        time: moment().format(),
      })
    );
  }

  async function SMSSendByQueue(sendQueue, start) {
    const body = generateBatchSenderBody({
      type: 'SMS',
      recipients: sendQueue,
      message: message,
      reversedWords: selectedHeaders.current,
      SMSSender: SMSSenderNumber,
      people: people,
    });
    await sendSMS(
      body,
      () =>
        setProgress(
          (start * 100) / (selected.length - 1) > 100
            ? 100
            : (start * 100) / (selected.length - 1)
        ),
      () =>
        usedispatch(
          addError({
            type: 'error',
            message: `SMS Batch Send Failed.`,
            time: moment().format(),
          })
        )
    );
  }

  async function WhatsAppSendByQueue(sendQueue, start) {
    const body = generateBatchSenderBody({
      type: 'WHATSAPP',
      recipients: sendQueue,
      message: message,
      reversedWords: selectedHeaders.current,
      WhatsAppSender: WhatsAppSenderNumber,
      people: people,
    });

    await sendWhatsApp(
      body,
      () =>
        setProgress(
          (start * 100) / (selected.length - 1) > 100
            ? 100
            : (start * 100) / (selected.length - 1)
        ),
      () =>
        usedispatch(
          addError({
            type: 'error',
            message: `WhatsApp Batch Send Failed.`,
            time: moment().format(),
          })
        )
    );
  }

  return (
    <>
      {(SMSLoading || WhatsAppLoading) && (
        <ProgressBar animated now={progress} />
      )}
      <h5>Template Message: </h5>
      <div className={styles.send_tools}>
        <div style={{ width: '900px', height: '100%' }}>
          <div className="alert alert-primary" role="alert">
            {`Please use `}
            <span style={{ color: 'red' }}>{`{{COLUMN HEADER}} `}</span>
            {`to generate customized message.`}
          </div>
          {resveredFlag && (
            <div className="alert alert-danger" role="alert">
              {`Template contains customized fields that are not in the contact table!`}
            </div>
          )}
          <textarea
            className="form-control"
            name="message"
            id="message"
            cols="30"
            rows="10"
            style={{ width: '100%', height: '200px' }}
            value={message}
            onChange={(e) => handleMessageChange(e.target.value)}
          ></textarea>
        </div>

        <div style={{ alignSelf: 'flex-end' }}>
          <input
            style={{ display: 'block', marginTop: '10px' }}
            type="button"
            value="Send SMS"
            className="btn btn-primary"
            onClick={SMSBtnHandler}
            disabled={SMSLoading}
          />
          <input
            style={{ display: 'block', marginTop: '10px' }}
            type="button"
            value="Send WhatsApp"
            className="btn btn-primary"
            onClick={WhatsAppBtnHandler}
            disabled={WhatsAppLoading}
          />
        </div>
      </div>
      <br />
    </>
  );
}

BatchSenderTools.propTypes = {};
