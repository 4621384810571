import React from 'react';
import { ToastContainer, Toast } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { removeError } from '../../Redux/Slices/ErrorSlices';

export function ErrorBoundary({ children }) {
  const errors = useSelector((state) => state.error.errors);
  const dispatch = useDispatch();

  function errorType(type) {
    switch (type) {
      case 'error':
        return 'bg-danger';
      case 'info':
        return 'bg-primary';
      case 'warning':
        return 'bg-warning';
      default:
        return 'bg-danger';
    }
  }
  return (
    <>
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ zIndex: 9999 }}
      >
        {errors.map((error) => {
          return (
            <Toast
              onClose={() => {
                dispatch(removeError(error));
              }}
              key={error.time}
            >
              <Toast.Header className={errorType(error.type)}>
                <strong className="me-auto text-white">{error.type}</strong>
              </Toast.Header>
              <Toast.Body>{error.message}</Toast.Body>
            </Toast>
          );
        })}
      </ToastContainer>
      {children}
    </>
  );
}
