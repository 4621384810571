import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { setManualSender } from '../../../Librarys/Redux/Slices/ManualSenderSlices';
import { useSMSTemplateSender } from '../../../Librarys/Hooks/useSMSTemplateSender';
import { useWhatsAppTemplateSender } from '../../../Librarys/Hooks/useWhatsAppTemplateSender';
import { useContactProfile } from '../../../Librarys/Hooks/useContactProfile';
import { useManualSender } from '../ManualSenderContext';
import { generateBatchSenderBody } from '../../../Librarys/Utils';
import { addError } from '../../../Librarys/Redux/Slices/ErrorSlices';
import { ButtonWithLoading, InputGroup } from '../../../Librarys/Components';
import { editNameMapping } from '../../../Librarys/Redux/Slices/GlobalSlices';
import { templateMessageChecker } from '../../../Librarys/Utils';

export function ManualSenderModal() {
  const open = useSelector((state) => state.manualSender.openManualSender);
  const usedispatch = useDispatch();
  const {
    phoneNumber,
    firstName,
    lastName,
    name,
    WhatsAppSenderNumber,
    SMSSenderNumber,
    updateManualSender,
  } = useManualSender();
  const [message, setMessage] = useState('');
  const [resveredFlag, setResveredFlag] = useState(false);
  const selectedHeaders = useRef([]);

  useEffect(() => {
    if (open === false) {
      setMessage('');
      setResveredFlag(false);
      selectedHeaders.current = [];
    }
  }, [open]);

  const [sendSMS, SMSLoading] = useSMSTemplateSender();
  const [sendWhatsApp, WhatsAppLoading] = useWhatsAppTemplateSender();
  const [saveContactProfile, , saveContactProfileLoading] = useContactProfile();

  function handleClose() {
    updateManualSender('clear');
    usedispatch(setManualSender(false));
  }

  const generateTemplateOptions = () => {
    const options = [];
    if (phoneNumber !== '') {
      options.push('PHONE NUMBER');
    }
    if (firstName !== '') {
      options.push('FIRST NAME');
    }
    if (lastName !== '') {
      options.push('LAST NAME');
    }
    if (name !== '') {
      options.push('NAME');
    }
    return options;
  };

  const handleMessageChange = (value) => {
    const selected = templateMessageChecker(
      value,
      setResveredFlag,
      setMessage,
      generateTemplateOptions()
    );
    selectedHeaders.current = [...selected];
  };

  function sendRequirementCheck() {
    if (message === '' || phoneNumber === '') {
      alert('Please fill required fields.');
      return false;
    }
    return true;
  }

  function saveProfileRequirementCheck() {
    if (name !== '' && phoneNumber !== '') {
      return true;
    }
    alert('Please fill required fields.');
    return false;
  }

  function SMSBtnHandler() {
    const body = generateBatchSenderBody({
      type: 'SMS',
      recipients: [phoneNumber],
      message: message,
      reversedWords: selectedHeaders.current,
      SMSSender: SMSSenderNumber,
      people: {
        [phoneNumber]: {
          NAME: name,
          'FIRST NAME': firstName,
          'LAST NAME': lastName,
          'PHONE NUMBER': phoneNumber,
        },
      },
    });

    if (!sendRequirementCheck()) {
      return;
    }

    sendSMS(
      body,
      () =>
        usedispatch(
          addError({
            type: 'info',
            message: `SMS Send Succeed  on ${phoneNumber}`,
            time: moment().format(),
          })
        ),
      () =>
        usedispatch(
          addError({
            type: 'error',
            message: `SMS Send Failed on ${phoneNumber}`,
            time: moment().format(),
          })
        )
    );
  }

  function WhatsAppBtnHandler() {
    const body = generateBatchSenderBody({
      type: 'WHATSAPP',
      recipients: [phoneNumber],
      message: message,
      reversedWords: selectedHeaders.current,
      WhatsAppSender: WhatsAppSenderNumber,
      people: {
        [phoneNumber]: {
          NAME: name,
          'FIRST NAME': firstName,
          'LAST NAME': lastName,
          'PHONE NUMBER': phoneNumber,
        },
      },
    });

    if (!sendRequirementCheck()) {
      return;
    }

    sendWhatsApp(
      body,
      () =>
        usedispatch(
          addError({
            type: 'info',
            message: `WhatsApp Send Succeed  on ${phoneNumber}`,
            time: moment().format(),
          })
        ),
      () =>
        usedispatch(
          addError({
            type: 'error',
            message: `WhatsApp Send Failed on ${phoneNumber}`,
            time: moment().format(),
          })
        )
    );
  }

  function saveProfileBtnHandler() {
    const body = [
      {
        phoneNumber: phoneNumber,
        name: name,
        firstName: firstName,
        lastName: lastName,
      },
    ];

    if (!saveProfileRequirementCheck()) {
      return;
    }

    saveContactProfile(
      body,
      () => {
        usedispatch(
          editNameMapping([{
            phoneNumber: phoneNumber,
            name: name,
            firstName: firstName,
            lastName: lastName,
          }])
        );
        usedispatch(
          addError({
            type: 'info',
            message: `Contact Profile Saving Succeed on ${phoneNumber}`,
            time: moment().format(),
          })
        );
      },
      () =>
        usedispatch(
          addError({
            type: 'error',
            message: `Contact Profile Saving Failed on ${phoneNumber}`,
            time: moment().format(),
          })
        )
    );
  }

  function inputTextHandler(type, value) {
    updateManualSender(type, value);
    handleMessageChange(message);
  }

  return (
    <Modal size="lg" show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Manually</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-primary" role="alert">
          {`If you send without template, contacts may not able to receive messages!`}
          <br />* NAME field is only required in Contact save.
          <br />* Template Message field is only required in send.
        </div>
        <InputGroup
          labelName="PHONE NUMBER (*)"
          id="phoneNumber"
          value={phoneNumber}
          onChange={(e) => inputTextHandler('phoneNumber', e.target.value)}
        />
        <InputGroup
          labelName="NAME (*)"
          id="name"
          value={name}
          onChange={(e) => inputTextHandler('name', e.target.value)}
        />
        <InputGroup
          labelName="FIRST NAME (Optional)"
          id="firstName"
          value={firstName}
          onChange={(e) => inputTextHandler('firstName', e.target.value)}
        />
        <InputGroup
          labelName="LAST NAME (Optional)"
          id="lastName"
          value={lastName}
          onChange={(e) => inputTextHandler('lastName', e.target.value)}
        />
        <InputGroup
          labelName="SMS Sender Number (Optional)"
          id="smsSender"
          value={SMSSenderNumber}
          onChange={(e) => inputTextHandler('SMSSenderNumber', e.target.value)}
          placeholder="SMS Sender Number"
        />
        <InputGroup
          labelName="WhatsApp Sender Number (Optional)"
          id="whatsappSender"
          value={WhatsAppSenderNumber}
          onChange={(e) =>
            inputTextHandler('WhatsAppSenderNumber', e.target.value)
          }
          placeholder="WhatsApp Sender Number"
        />
        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Template Message (*)
          </label>
          <div className="alert alert-primary" role="alert">
            {`Please use `}
            <span style={{ color: 'red' }}>{`{{LABEL NAME}} `}</span>
            {`to generate customized message.`}
          </div>
          {resveredFlag && (
            <div className="alert alert-danger" role="alert">
              {`Template contains customized fields that are not in the contact table or they are not filled!`}
            </div>
          )}
          <textarea
            className="form-control"
            id="message"
            rows="3"
            value={message}
            onChange={(e) => handleMessageChange(e.target.value)}
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={saveProfileBtnHandler}>
          <ButtonWithLoading
            title={'Save Contact Profile'}
            loading={saveContactProfileLoading}
          />
        </Button>
        <Button variant="primary" onClick={SMSBtnHandler}>
          <ButtonWithLoading title={'Send SMS'} loading={SMSLoading} />
        </Button>
        <Button variant="primary" onClick={WhatsAppBtnHandler}>
          <ButtonWithLoading
            title={'Send WhatsApp'}
            loading={WhatsAppLoading}
          />
        </Button>
        <Button variant="warning" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
