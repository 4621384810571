import useFetch from 'use-http';
export const useWhatsAppTemplateSender = () => {
  const { request, post, response, loading, error } = useFetch(
    process.env.REACT_APP_SENDER_API,
    { cachePolicy: 'no-cache' }
  );
  async function sendWhatsApp(body, onSuccess, onError) {
    await post('/whatsapp-t', body);
    if (response.ok) {
      onSuccess();
    }
    if (error) {
      onError();
    }
  }
  return [sendWhatsApp, loading];
};
