import React from 'react';
import PropTypes from 'prop-types';
import { CSVReader } from 'react-papaparse';
import { Button, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { initial, clear } from '../../Redux/Slices/SenderSlices';
import { addError } from '../../Redux/Slices/ErrorSlices';
import { setManualSender } from '../../Redux/Slices/ManualSenderSlices';

const buttonRef = React.createRef();

function handleOnError(err, file, inputElem, reason) {
  console.log('---------------------------');
  console.log(err);
  console.log('---------------------------');
}

function handleOpenDialog(e) {
  // Note that the ref is set async, so it might be null at some point
  if (buttonRef.current) {
    buttonRef.current.open(e);
  }
}

function handleRemoveFile(e) {
  // Note that the ref is set async, so it might be null at some point
  if (buttonRef.current) {
    buttonRef.current.removeFile(e);
  }
}

export function FileUpload({ children }) {
  const { people, SMSSelected, WhatsAppSelected, tableHeaders } = useSelector(
    (state) => state.sender
  );
  const usedispatch = useDispatch();

  const handleOnFileLoad = (data) => {
    const [headers, people, SMSSelected, WhatsAppSelected] =
      cleanAndSortDataOnLoad(data);
    if (headers === undefined || people === undefined) {
      alert('Invalid Contact File!');
      return;
    }

    if (!headers.includes('PHONE NUMBER')) {
      alert('Contact File Must Include Phone Number!');
      return;
    }
    usedispatch(
      initial({
        tableHeaders: headers,
        people: people,
      })
    );
  };

  const handleOnRemoveFile = () => {
    usedispatch(clear());
  };

  const cleanAndSortDataOnLoad = ([header, ...rawData]) => {
    const checkFieldValid = (rowData) => {
      let addUp = '';
      if (rowData.length > 0) {
        for (let i = 0; i < rowData.length; i++) {
          addUp += rowData[i].replace(/[\n\r]/g, '');
        }
      }
      if (addUp === '') {
        return false;
      }
      return true;
    };

    if (header === undefined) return [];

    const headers = [];

    let phoneNumberIndex = 0;
    header.data.forEach((e, index) => {
      if (e.toUpperCase() === 'PHONE NUMBER') {
        phoneNumberIndex = index;
      }
      headers.push(e.toUpperCase());
    });

    const people = {};
    const SMSSelected = new Set();
    const WhatsAppSelected = new Set();
    const duplicatePeople = new Set();
    let duplicatePeopleCount = 0;
    if (rawData.length > 0) {
      rawData.forEach((row) => {
        if (checkFieldValid(row.data)) {
          let cur = {};
          for (let i = 0; i < headers.length; i++) {
            cur[headers[i]] = row.data[i];
          }
          if (_.isNil(people[row.data[phoneNumberIndex]])) {
            people[row.data[phoneNumberIndex]] = cur;
            SMSSelected.add(row.data[phoneNumberIndex]);
            WhatsAppSelected.add(row.data[phoneNumberIndex]);
          } else {
            duplicatePeople.add(row.data[phoneNumberIndex]);
            duplicatePeopleCount++;
          }
        }
      });
    }
    if (duplicatePeople.size > 0 && duplicatePeopleCount > 0) {
      usedispatch(
        addError({
          type: 'warning',
          message: `The CSV file imported has ${duplicatePeopleCount} duplicate phone numbers, they are [${[
            ...duplicatePeople,
          ].join(',')}]`,
          time: moment().format(),
        })
      );
    }
    return [headers, people, SMSSelected, WhatsAppSelected];
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        config={{ skipEmptyLines: true }}
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <div className="hstack mb-3">
            <h5
              className="d-block align-self-center"
              style={{ marginRight: '10px' }}
            >
              Please Upload contact file (*.csv):{' '}
            </h5>
            <button
              className="btn btn-outline-secondary align-self-center "
              type="button"
              onClick={handleOpenDialog}
            >
              Browse file
            </button>
            <input
              type="text"
              className="form-control align-self-center "
              placeholder=""
              aria-label="file name"
              value={(file && file.name) || ''}
              style={{ maxWidth: '40%' }}
              readOnly
            />
            <Stack direction="horizontal" gap={3} className="ms-auto">
              <Button
                variant="primary"
                onClick={() => usedispatch(setManualSender(true))}
              >
                Send Manually
              </Button>
              <Button variant="warning" onClick={handleRemoveFile}>
                Start Over
              </Button>
            </Stack>
          </div>
        )}
      </CSVReader>
    </div>
  );
}

FileUpload.propTypes = {
  children: PropTypes.node,
};
