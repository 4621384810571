export function generateBatchSenderBody({
  type,
  recipients,
  message,
  reversedWords,
  SMSSender,
  WhatsAppSender,
  people
}) {
  const SMSArray = [];
  switch (type) {
    case 'SMS':
      if (recipients.length > 0) {
        recipients.forEach((r) => {
          SMSArray.push(`+${Number(r)}`);
        });
      }
      const reqBody = { tos: SMSArray, template: message };
      if (SMSSender !== '') {
        reqBody['from'] = SMSSender;
      }
      reversedWords.forEach((e) => {
        const curField = [];
        recipients.forEach((r) => {
          curField.push(`${people[r][e]}`);
        });
        reqBody[e] = curField;
      });

      return reqBody;
    case 'WHATSAPP':
      if (recipients.length > 0) {
        recipients.forEach((r) => {
          SMSArray.push(`+${Number(r)}`);
        });
      }
      // eslint-disable-next-line no-case-declarations
      const reqBodyWhatsApp = { tos: SMSArray, template: message };
      if (WhatsAppSender !== '') {
        reqBodyWhatsApp['from'] = `+${Number(WhatsAppSender)}`;
      }
      reversedWords.forEach((e) => {
        const curField = [];
        recipients.forEach((r) => {
          curField.push(`${people[r][e]}`);
        });
        reqBodyWhatsApp[e] = curField;
      });
      return reqBodyWhatsApp;
    default:
      break;
  }
}
