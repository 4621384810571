import React from 'react';
import PropTypes from 'prop-types';

import styles from './SinglePageContainer.module.scss';

export function SinglePageContainer({ children }) {
  return <div className={styles.singlePageContainer}>{children}</div>;
}

SinglePageContainer.propTypes = {
  children: PropTypes.node,
};
