import React from 'react';
import _ from 'lodash';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';

export const ThemeProvider = ({
  children,
  // TODO: need to define default cobrand css
  cobrand = 'default',
  StylesComponent,
}) => {
  return (
    <BootstrapThemeProvider prefixes={cobrand}>
      {StylesComponent}
      {children}
    </BootstrapThemeProvider>
  );
};
